import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Recykladční znaky" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "recyklační-znaky"
    }}>{`Recyklační znaky`}</h1>
    <p><strong parentName="p">{`todo`}</strong>{` - text a ukázka`}</p>
    <hr />
    <a href="javascript:history.back()">Zpět</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      